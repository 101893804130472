// extracted by mini-css-extract-plugin
export var card = "Works-module--card--KCNwT";
export var cardTitle = "Works-module--cardTitle--DF92H";
export var cardImage = "Works-module--cardImage--3PS25";
export var cardTexts = "Works-module--cardTexts--1yF6b";
export var contentWrapper = "Works-module--contentWrapper--o1lZy";
export var linkImg = "Works-module--linkImg--lkGjY";
export var githubImg = "Works-module--githubImg--tRgRl";
export var useLanguage = "Works-module--useLanguage--PuqtT";
export var start = "Works-module--start--BNjs0";
export var header = "Works-module--header--+I5Sh";
export var githubLink = "Works-module--githubLink--5d1zV";