import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import * as style from "../components/Works.module.css";

const Works = ({ data }) => {
  return (
    <Layout pageTitle="Works">
      <div className={style.start}>
        <h2 className={style.header}>Works</h2>
        <a className={style.githubLink} href="https://github.com/Tzarino">
          <StaticImage
            className={style.githubImg}
            src="../images/githubLogo.png"
            alt="github logo"
          />
          GitHub
        </a>
      </div>

      <div className={style.contentWrapper}>
        {data.allMdx.nodes.map((node) => {
          const thumbnail = getImage(node.frontmatter.thumbnail);
          return (
            <article key={node.id} className={style.card}>
              <GatsbyImage
                image={thumbnail}
                alt={node.frontmatter.thumbnail_alt}
                className={style.cardImage}
              />
              <div className={style.cardTexts}>
                <a href={node.frontmatter.link} className={style.cardTitle}>
                  {node.frontmatter.title}
                  <StaticImage
                    src="../images/link.png"
                    alt="link"
                    className={style.linkImg}
                  />
                </a>

                <p className={style.useLanguage}>
                  {node.frontmatter.use_language}
                </p>
                <MDXRenderer>{node.body}</MDXRenderer>
              </div>
            </article>
          );
        })}
      </div>
    </Layout>
  );
};
// TODO : graphqlでデータフェッチ。画像やテキストを取得。その後data.allMDX....とかで.....

export const query = graphql`
  {
    allMdx(
      filter: { fields: { sourceInstanceName: { eq: "works" } } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      nodes {
        frontmatter {
          link
          title
          thumbnail_alt
          thumbnail {
            childImageSharp {
              gatsbyImageData
            }
          }
          use_language
        }
        body
        id
      }
    }
  }
`;

export default Works;
